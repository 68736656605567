<template>
    <div class="RoleEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules" size="small">
                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model.trim="form.name" />
                </el-form-item>
                <el-form-item label="菜单类型" prop="level">
                    <el-radio-group v-model.number="form.level" disabled>
                        <el-radio :label="0">目录</el-radio>
                        <el-radio :label="1">菜单</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上级菜单" prop="parentCode" v-if="form.level === 1">
                    <el-select v-model="form.parentCode">
                        <el-option v-for="p in catalogs" :label="p.name" :value="p.code" :key="p.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" prop="orderIndex">
                    <el-input-number v-model="form.orderIndex" :min="0" :max="99" :precision="2" :controls="false" />
                </el-form-item>
                <el-form-item label="权限基本标识" prop="privilegeFlag">
                    <el-input v-model="form.privilegeFlag" readonly disabled />
                </el-form-item>
                <el-form-item label="" prop="flagSuffixList">
                    <el-checkbox-group v-model="form.flagSuffixList">
                        <el-checkbox
                            v-for="s of suffixList"
                            :label="s.value"
                            :key="s.value"
                            :disabled="disableSuffix(s)"
                            v-if="showSuffix(s)"
                        >
                            {{ s.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'MenuEdit',
    data() {
        return {
            form: {
                code: '',
                name: '',
                level: 0,
                orderIndex: undefined,
                privilegeFlag: '',
                parentCode: '',
                flagSuffixList: ['.show'],
            },
            dbFlagSuffixList: [],
            url: {
                patch: '/system/menu/editExtend/',
                queryDetail: '/system/menu/info_c/',
                searchCatalogUrl: '/system/menu/listTree?level=0',
            },
            rules: {
                name: [{ required: true, min: 1, max: 10, message: '名称必填,1到10个字', trigger: 'blur' }],
                level: [{ required: true, trigger: 'blur', message: '请选择类型' }],
                orderIndex: [{ required: true, trigger: 'blur', message: '排序必填' }],
            },
            // 目录(一级菜单)
            catalogs: [],
            suffixList: [
                { name: '显示', value: '.show' },
                { name: '访问', value: '.open' },
                { name: '创建', value: '.create' },
                { name: '编辑', value: '.edit' },
                { name: '删除', value: '.delete' },
                { name: '审核', value: '.review' },
                { name: '变更价格', value: '.price' },
                { name: '变更库存', value: '.stock' },
                { name: '导出', value: '.export' },
            ],
        };
    },
    mounted() {
        this.form.code = this.$route.params.code;
        UrlUtils.QueryRemote(this, this.url.queryDetail + this.form.code, (res) => {
            UrlUtils.QueryRemote(this, this.url.searchCatalogUrl, (res2) => {
                this.catalogs = res2;
                this.form = res;
                this.dbFlagSuffixList = res.flagSuffixList;
            });
        });
    },
    methods: {
        handleSave() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, this.url.patch + this.form.code, this.form, null, () => {
                    _this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
        // 目录只展示显示和访问,菜单全部展示
        showSuffix(s) {
            return s.name === '显示' || s.name === '访问' || this.form.level === 1;
        },
        // 禁用 因为显示一定被勾选所以禁用. 已打勾的也是禁用 业务上不允许取消勾选
        disableSuffix(s) {
            return s.name === '显示' || this.dbFlagSuffixList.indexOf(s.value) !== -1;
        },
    },
};
</script>
